@import 'antd/dist/reset.css';
/* @import 'antd/dist/antd.css'; */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  background: #f5f5f5;
}
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}


p{
  margin: 0;
  padding: 0;
}
td {
  vertical-align: middle;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.pt10 {
  padding-top: 10px;
}
.pb10 {
  padding-bottom: 10
}
.plr20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ptb10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.ml5 {
  margin-left: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.ml10 {
  margin-left: 10px;
}

.ml12 {
  margin-left: 12px !important;

}
.plr15 {
	padding-left: 15px;
	padding-right: 15px;
}
.mtb5{
	margin-top: 5px;
	margin-bottom: 5px;
}
.mr12 {
  margin-right: 12px;
}
.ml20 {
  margin-left: 20px;
}
.mr5 {
  margin-right: 5px;
}
.mlr5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr10i {
	margin-right: 10px !important;
}
.mt15 {
  margin-top: 15px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr15 {
  margin-right: 15px;
}
.mlr10 {
  margin-right: 10px;
  margin-left: 10px;
}
.mtb10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mtb15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.b {
  font-weight: bold;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}
.flex-s-c {
  display: flex;
  align-items: center;
}
.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-b-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.j-sb {
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}

.red {
  color: red;
}
.p-color {
  color: #096BED;
}
.bgc-f8 {
  background-color: #f8f8f8;
}


.other-line {
  display: inline-block;
  width: 13px;
  height: 2px;
  background-color: #979797;
  
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}