// Color
$primary-color          : #096BED;
$menu-color             : #fff;
$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #2958A6;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.container {
	display: flex;
	min-width: 950px;
	height: 100%;
  .content{
    height: 100%;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    // padding: 20px;
    border-radius: 5px;
    overflow-x: hidden;
  }
	:global {
		.ant-layout-sider {
			box-sizing: border-box;
			background-color: #fff;
			transition: all 0.2s;
		}
		.ant-layout {
			/* 防止 tabs 超出不收缩 */
			overflow-x: hidden;
			.ant-layout-content {
				box-sizing: border-box;
				flex: 1;
				padding: 10px 12px;
				overflow-x: hidden;
			}
		}
	}
}
.notice-wrap {
	position: fixed;
	bottom: 100px;
	right: 10px;
	z-index: 100;
	padding: 15px;
	width: 320px;
	box-sizing: border-box;
	background: linear-gradient(180deg, #FFF 0%, #F4F9FF 100%);
	box-shadow: 0 0 8px 0 #00000040;
	img {
		width: 38px;
		height: 38px;
		position: absolute;
		top: 0px;
		left: 0px;
	}
	.close {
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
	}
	.notice-head {
		color: #333333;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;
	}
	.notice-content {
		color: #333333;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-top: 20px;
		line-height: 20px;
	}
	.notice-time {
		color: #888888;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-top: 8px;
		line-height: 20px;
	}
	.btn-wrap {
		margin-top: 10px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
.header {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 36px;
	background: #F5F7FA;
	.header-lf {
		display: flex;
		align-items: center;
		img {
			width: 30px;
			height: 30px;
		}
		span {
			color: #3F6AE4;
			font-size: 20px;
			font-weight: 500;
			font-family: "PingFang SC";
			text-align: left;
			margin-left: 10px;
		}
	}
	.header-rt {
		display: flex;
		justify-content: flex-end;
		.path-item {
			margin-right: 48px;
			color: #333333;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			cursor: pointer;
			position: relative;
			&.active {
				color: #3f6ae4;
			}
			.vertical {
				width: calc(100% - 10px);
				height: 3px;
				background: #3F6AE4;
				border-radius: 3px;
				position: absolute;
				left: 5px;
				bottom: -4px;
			}
		}
		.down-center {
			width: 96px;
			height: 30px;
			border-radius: 30px;
			background: #3F6AE4;
			color: #ffffff;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 30px;
			text-align: center;
			cursor: pointer;
		}
	}
}


